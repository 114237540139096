import {Experiments} from './Experiments';
import {STORES_PREMIUM} from '../../editor-script/constants';

export class ExperimentsApi {
  private _experiments: Experiments;
  private _hasStoresPremium: boolean;

  constructor(private readonly instance: string) {}

  public async fetch(): Promise<void> {
    const experimentsResponse = await fetch('/_api/wix-ecommerce-storefront-web/api', {
      method: 'post',
      body: JSON.stringify({
        query: require('!raw-loader!./getConfig.graphql'),
        source: 'WixStoresWebClient',
        operationName: 'getConfig',
      }),
      headers: {
        Authorization: this.instance,
        'Content-Type': 'application/json; charset=utf-8',
      },
    })
      .then((data) => data.json())
      .then((data) => {
        this._hasStoresPremium = data?.data?.premiumFeatures?.some((feature) => feature.name === STORES_PREMIUM);
        return (data.data.experiments || []).reduce((acc, e) => {
          acc[e.name] = e.value;
          return acc;
        }, {});
      });
    this._experiments = new Experiments(experimentsResponse);
  }

  public get experiments() {
    return this._experiments;
  }

  public get hasStoresPremium() {
    return this._hasStoresPremium;
  }

  public enabled(specName: string): boolean {
    return this.experiments.enabled(specName);
  }
}
